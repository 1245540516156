import React from 'react'
import Layout from 'src/layouts/BaseLayout'
import { Wrapper } from './style'
import pageContext from './pageContext.json'

import Hero from './sections/hero/_index'
import ProtegerInformacao from './sections/protegerInformacao/_index'
import Principios from './sections/principios/_index'
import Abrangencias from './sections/abrangencias/_index'
import Responsabilidades from './sections/responsabilidades/_index'
import Conformidade from './sections/conformidade/_index'

const PoliticasDePrivacidade = () => {
  const lang = 'pt'
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero lang={lang} />
        <ProtegerInformacao lang={lang} />
        <Principios lang={lang} />
        <Abrangencias lang={lang} />
        <Responsabilidades lang={lang} />
        <Conformidade lang={lang} />
      </Layout>
    </Wrapper>
  )
}

export default PoliticasDePrivacidade
